<script lang="ts" setup>
    import { LazyMolContentDialog, AtmLink } from '#components';
    import type { FeatureCard, Dialog } from '~/@types/cms';
    import type { IWidgetProps } from '~/composables/widgets';

    const props = defineProps<IWidgetProps<FeatureCard>>();

    const nuxtApp = useNuxtApp();
    const { data, getValueSequence } = useWidget(props);

    const {
        backgroundColorClass,
        backgroundColorStyle,
        buttonColorClass,
        buttonColorStyle,
        buttonLabelColorClass,
        buttonLabelColorStyle,
        textColorStyle,
        textColorClass,
    } = useStyleCustom(props.data.style);

    const dialogData = computed<Dialog | undefined>(() => {
        if (!data.dialog) return;
        const dialog = getValueSequence(data.dialog);
        return dialog[0];
    });

    const hasDialog = computed(() => Boolean(dialogData.value));

    const component = computed(() => {
        if (hasDialog.value) {
            return 'button';
        } else if (!data.link) {
            return 'div';
        } else {
            return AtmLink;
        }
    });

    const cardTitle = computed(() => {
        return dialogData?.value?.title || data.title;
    });

    const handleOpenDialog = () => {
        nuxtApp.$gtm.selectContent({
            content_type: 'feature_slide',
            content_id: `${props.componentIndex}`,
            content_action: hasDialog.value ? 'overlay' : 'link',
        });

        if (!dialogData.value || !data.link) return;
        nuxtApp.$eventEmitter.emit('global-dialog', {
            content: {
                component: LazyMolContentDialog,
                props: {
                    image: dialogData.value.image,
                    title: dialogData.value.title,
                    description: dialogData.value.description,
                },
            },
        });
    };
</script>
<template>
    <component
        :is="component"
        :class="[
            'gtm-click-feature mol-feature-card slide relative flex aspect-2/3 h-full flex-col overflow-hidden rounded-2xl p-6 lg:mb-5 lg:mt-2',
            {
                'transition-all duration-300 ease-out lg:hover:-translate-y-1 lg:hover:drop-shadow-xl lg:focus:-translate-y-1 lg:focus:drop-shadow-xl':
                    component !== 'div',
            },
            backgroundColorClass,
        ]"
        :style="[backgroundColorStyle]"
        @click="handleOpenDialog"
        :data-content-id="data.id"
        :data-content-name="data.title"
        :type="hasDialog ? 'button' : undefined"
        :link="!hasDialog ? data.link : undefined"
        link-only>
        <span class="absolute bottom-0 left-0 right-0 top-0">
            <atm-image
                :data="data.image"
                sizes="100vw md:50vw lg:309px xl:390px"
                preset="2to3"
                cover />
        </span>
        <span class="relative mt-auto flex w-full flex-col gap-2">
            <atm-heading
                v-if="cardTitle"
                level="span"
                size="sm"
                :class="['whitespace-pre-wrap text-left', textColorClass, data.style?.theme === 'dark' ? 'text-white' : 'text-woom-black']"
                :style="[textColorStyle]">
                {{ cardTitle }}
            </atm-heading>
            <atm-button
                v-if="data?.link?.label"
                :styling="data.style?.theme === 'dark' ? 'solid-invert' : 'solid-secondary'"
                :class="[buttonColorClass, buttonLabelColorClass]"
                :style="[buttonColorStyle, buttonLabelColorStyle]"
                block
                kind="decorative">
                <renderer-html :content="woomTextFormat(data?.link?.label)" />
                <template
                    #icon
                    v-if="hasDialog">
                    <woom-icon-plus-circle class="h-6 w-6 fill-current" />
                </template>
            </atm-button>
        </span>
    </component>
</template>
